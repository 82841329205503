import React from "react";

import "../App.css";
import FormComponent from ".././comp/FormData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import svg from "../assets/ZKZg.gif";
import url from "../utils/api";

const Registration = () => {
  const [formDataArray, setFormDataArray] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { id } = useParams();
  const [formData, setformData] = useState({});
  const [isLiveImageCaptured, setisLiveImageCaptured] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  //   const []

  //   const getCount = async () => {
  //     let res = await fetch(`http://localhost:8000/data`);
  //     let res2 = await res.json();
  //     setTotalCount(res2.length + 1);
  //   };

  // const getCount = async () => {
  //   let res = await fetch(`https://bssa-pdfnew.vercel.app/data`);
  //   let res2 = await res.json();
  //   setTotalCount(res2.length + 1)

  // };

  // https://bssa-pdfnew.vercel.app/data

  //   const getData = async () => {
  //     let res = await fetch(`http://localhost:8000/data?_page=${page}&_limit=1`);
  //     let res2 = await res.json();
  //     setFormDataArray(res2);
  //   };

  // const getData = async () => {
  //   let res = await fetch(`https://bssa-pdfnew.vercel.app/data?_page=${page}&_limit=1`);
  //   let res2 = await res.json();
  //   setFormDataArray(res2);
  // };

  // const getData = async () => {
  //   let res = await fetch(`http://localhost:8000/data`);
  //   let res2 = await res.json();
  //   setFormDataArray(res2);
  // };

  // backend data
  // useEffect(() => {

  // }, [third]);

  const getData = async () => {
    setisLoading(true);
    let res = await fetch(`${url}/api/fetchDetails/${id}`);
    let res2 = await res.json();
    setisLoading(false);

    // console.log(res2[0]);
    setformData(res2[0]);
    // console.log(id)
  };

  // const getData = async () => {  // vercel data
  //   let res = await fetch(`https://bssa-pdfnew.vercel.app/data`);
  //   let res2 = await res.json();

  //   const obj = res2.find((x) => x.adharNo == id);
  //   console.log(obj);
  //   setformData(obj);
  //   console.log(id);
  // };

  useEffect(() => {
    getData();
  }, [isLiveImageCaptured]);
  // console.log(isLiveImageCaptured);

  //   useEffect(() => {
  //     getCount();
  //   }, []);
  // console.log(formDataArray);

  //   const handlePreviousPage = () => {
  //     if (page > 1) {
  //       setPage(page - 1);
  //     }
  //   };

  //   const handleNextPage = () => {
  //     const totalPages = Math.ceil(totalCount / 1);
  //     if (page < totalPages) {
  //       setPage(page + 1);
  //     }
  //   };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          {" "}
          <img src={svg} alt="" style={{ height: "100px", width: "100px" }} />
        </div>
      ) : (
        <div key={formData?.Timestamp}>
          <FormComponent
            formData={formData}
            setisLiveImageCaptured={setisLiveImageCaptured}
          />
        </div>
      )}
    </div>
  );
};

export default Registration;
