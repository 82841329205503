// src/CameraCapture.js

import React, { useRef, useCallback, useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import Webcam from "react-webcam";
// import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import url from "../utils/api";
const CameraCapture = ({ SL_NO, setisLiveImageCaptured }) => {
  const webcamRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [issubmitted, setissubmitted] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);

  useEffect(() => {
    if (issubmitted) {
      window.location.reload();
      // This will refresh the page
    }
  }, [issubmitted]);

  const handleCapturePhoto = () => {
    setUploadingImg(true);
    const video = webcamRef.current.video;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(uploadToCloudinary, "image/jpeg", 1.0);
  };

  const uploadToCloudinary = async (blob) => {
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("upload_preset", "KSS1STORAGE");

    //KSS1STORAGE    app name
    //surajbrand       cloud name

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/surajbrand/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImageData(data.secure_url);
      setUploadingImg(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const updateLiveImgae = () => {
    // sending PATCH request with fetch API in javascript
    setisLoading(true);
    // console.log("patchrequest");
    fetch(`${url}/api/upadateImage`, {
      method: "PATCH",
      body: JSON.stringify({
        LiveImageURL: imageData,
        SL_NO: SL_NO,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        // console.log(response, "after response");
        response.json();
        setissubmitted(true);
        setisLoading(false);
      })
      .then((json) => console.log("image url checked"))
      .catch((error) => console.error(error));
    // axios
    //   .patch("http://localhost:5000/api/upadateImage", {
    //     LiveImageURL: imageURL,
    //     adharNo: adharNo,
    //   })
    //   .then((response) => console.log("patchrequest", response.data))
    // .catch((error) => console.error(error));
  };

  // const captureImage = useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImageData(imageSrc);

  //   setCheckAttendance(true);
  // }, []);

  const retakeImage = () => {
    setImageData(null);
  };

  return (
    <div>
      {imageData ? (
        <img src={imageData} alt="Captured" width="150" height="150" />
      ) : uploadingImg == true ? (
        <div
          style={{ height: "200px", width: "200px", backgroundColor: "gray" }}
        >
          <img
            src="https://cdn.dribbble.com/users/1186469/screenshots/2926719/upload.gif"
            height="200px"
            width="200px"
          />
        </div>
      ) : (
        <Webcam
          audio={false}
          height="200px"
          width="200px"
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
      )}

      {imageData && issubmitted === false ? (
        <div>
          {" "}
          <button
            onClick={retakeImage}
            style={{ padding: "8px", margin: "8px" }}
          >
            Retake Image
          </button>
          <button
            onClick={updateLiveImgae}
            style={{ padding: "8px", margin: "8px" }}
          >
            {isLoading == true ? (
              <Spinner animation="border" color="red" role="status">
                <span className="visually-hidden">submitting...</span>
              </Spinner>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      ) : (
        <button
          onClick={handleCapturePhoto}
          style={{ padding: "8px", margin: "8px" }}
        >
          {uploadingImg == true ? (
            <Spinner animation="border" color="red" role="status">
              <span className="visually-hidden">uploading...</span>
            </Spinner>
          ) : (
            "Capture Image"
          )}
        </button>
      )}
    </div>
  );
};

export default CameraCapture;
